import React, { useEffect } from 'react';
import fsk from '../../Misc/fsk.png';
import tgk from '../../Misc/tgk.png';
import sm from '../../Misc/sevmash.png';
import gt from '../../Misc/gupt.jpg';
import mor from '../../Misc/Mhz_logo.gif';
import uz from '../../Misc/uz.png';

function Main(props) {
	const [partners, setPartners] = React.useState([]);
	useEffect(() => {
		setPartners([
			{ img: fsk, link: 'https://www.fsk-ees.ru/', title: 'ФСК ЕЭС' },
			{ img: sm, link: 'https://www.sevmash.ru/', title: 'Севмаш' },
			{ img: tgk, link: 'https://www.tgc1.ru/', title: 'ТГК-1' },
			{ img: gt, link: 'https://www.gptek.spb.ru/', title: 'ГУП ТЭК' },
			{ img: mor, link: 'http://www.zavodm.su/', title: 'Завод имени Морозова' },
			{ img: uz, link: 'http://uztec.ru/', title: 'Юго-Западная ТЭЦ' },
		]);
	}, []);
	return (
		<div className="container">
			<div className="row">
				<h1>О компании</h1>
				<p>
					Наши специалисты имеют опыт работы поставки КИП с 1999 года. Становление коллектива
					пришлось на сложный экономический период, когда процветали коммерческие схемы
					взаиморасчётов по бартеру и энергозачетам. Тот опыт позволил в настоящее время создать
					прочную систему связей с ответственными и профессиональными людьми и предприятиями,
					благодаря которым Компания «Энерготраст» обеспечила свою конкурентоспособность. Благодаря
					тесному сотрудничеству с такими компаниями как «НПП «Элемер», «Solinst», «АВВ», «WIKA»
					наша компания способна предложить уникальное и сложнейшее оборудование в сфере автоматики,
					увеличивая тем самым долю наших партнеров на рынке .
				</p>
				<p>
					Качество поставляемой продукции и удовлетворение всех требований заказчика является
					приоритетным направлением, поэтому Компания «Энерготраст» уделяет особое внимание
					результатам исследовательской работы и научно-технических разработок, позволяющих выводить
					на рынок инновационную продукцию. Одним из критериев наших технико-коммерческих
					предложений для заказчиков является максимальный комфорт и сбалансированное соотношение
					цены и качества. Среди наших партнеров и постоянных клиентов предприятия
					целлюлозно-бумажной, оборонной, машиностроительной, металлургической отрасли и предприятия
					топливно-энергетического комплекса такие как, АО «ПО Севмаш», ПАО «Газпром», ПАО «ФСК
					ЕЭС», ГУП «ТЭК СПб», НИТИ им. Александрова, АО «ЦКБМ» и многие другие. Компания
					«Энерготраст» гарантирует высокий уровень сервиса и качество поставляемой продукции,
					которая проверена временем.
				</p>
			</div>
			<h3>Наши клиенты:</h3>
			<div className="row flex">
				{partners.map((el) => {
					return (
						<div key={el.img} className="partner">
							<a href={el.link}>
								{' '}
								<img src={el.img} alt="logo" title={el.title} />{' '}
							</a>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default Main;
